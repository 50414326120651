<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  @if (showViewForm) {
    <div class="formgrid grid p-fluid">
      <div class="w-6">
        <div class="col-12">
          <app-basic-input
            label="Name"
            [placeholder]="'Enter Name'"
            [control]="formGroup?.controls?.name"
            [viewMode]="fieldViewMode"
          ></app-basic-input>
        </div>
        <div class="col-12">
          <app-drop-down-input
            [label]="'Classification'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="listOfTypes"
            [optionValue]="'value'"
            [control]="formGroup?.controls?.controlClassification"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
          ></app-drop-down-input>
        </div>
        <div class="col-12">
          <app-suggestion-input
            [label]="'Frequency'"
            [placeholder]="'Ex: 7 days'"
            [control]="formGroup?.controls?.controlFrequency"
            [viewMode]="fieldViewMode"
            [items]="[
              { label: 'Yearly', value: 365 },
              { label: 'Quarterly', value: 90 },
              { label: 'Monthly', value: 30 },
              { label: 'Weekly', value: 7 },
              { label: 'Daily', value: 1 },
            ]"
            [min]="0"
            [maxFractionDigits]="0"
          >
          </app-suggestion-input>
        </div>
        <div class="col-12">
          <app-number-input
            [label]="moduleFieldString + '.controlsNumber.label' | translate: 'Controls Number'"
            [placeholder]="moduleFieldString + '.controlsNumber.label' | translate: 'Controls Number'"
            [name]="'controlsNumber'"
            [showButtons]="true"
            [viewMode]="'view'"
            [control]="formGroup?.controls?.controlsNumber"
            [type]="'number'"
          ></app-number-input>
        </div>
        <!-- <div class="col-12">
                <app-target-code-selector [label]="'Policy'"
                    [placeholder]="'Search Policies'"
                    [control]="formGroup?.controls?.policy"
                    [viewMode]="fieldViewMode"
                    [multi]="false"
                    [optionLabel]="'name'"
                    [optionValue]="undefined"
                    [dataKey]="'code'"
                    [targetTypes]="['POLICY']">
                </app-target-code-selector>
            </div>
            <div class="col-12">
                <app-target-code-selector [label]="'Policy Body Section'"
                    [placeholder]="'Search Sections'"
                    [control]="formGroup?.controls?.policySection"
                    [viewMode]="fieldViewMode"
                    [multi]="false"
                    [optionLabel]="'name'"
                    [optionValue]="'code'"
                    [targetTypes]="['POLICY_BODY_SECTION']">
                </app-target-code-selector>
            </div> -->
      </div>
      <div class="w-6">
        <div class="col-12 md:col-12">
          <app-text-editor
            [height]="'100px'"
            [advanced]="true"
            [name]="'textarea'"
            label="Description"
            [placeholder]="'Enter Text...'"
            [control]="formGroup?.controls?.description"
            [viewMode]="fieldViewMode"
          ></app-text-editor>
        </div>
      </div>
    </div>
  } @else {
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          label="Name"
          [placeholder]="'Enter Name'"
          [control]="formGroup?.controls?.name"
          [viewMode]="canEditField"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="'Owner'"
          [placeholder]="'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.owner"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="fieldViewMode"
        >
        </app-user-and-group-selector>
        <!-- <app-entity-category-tree-selector [label]="'Owner'" [categoryList]="respList"
        [placeholder]="'Search Entities'" [control]="formGroup?.controls?.owner" [multi]="false"
        [selectedEntities]="[data?.owner]" [viewMode]="fieldViewMode" [dropDown]="true">
      </app-entity-category-tree-selector> -->
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="'Respondent'"
          [placeholder]="'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.attestationRespondent"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="fieldViewMode"
        >
        </app-user-and-group-selector>
        <!-- <app-entity-category-tree-selector
      [label]="'Respondent'"
      [categoryList]="respList"
      [placeholder]="'Search Responsibilities'"
      [control]="formGroup?.controls?.attestationRespondent"
      [multi]="false"
      [viewMode]="fieldViewMode"
      [selectedEntities]="[data?.attestationRespondent]"
      [dropDown]="true"
      >
    </app-entity-category-tree-selector> -->
      </div>
      <!-- <div class="col-12 md:col-6">
            <app-drop-down-input [label]="'Classification'"
                [placeholder]="'Select Item'"
                [name]="'type'"
                [optionLabel]="'label'"
                [items]="listOfTypes"
                [optionValue]="'value'"
                [control]="formGroup?.controls?.controlClassification"
                [viewMode]="canEditField"
                [badgeView]="true"></app-drop-down-input>
        </div> -->
      <div class="col-12 md:col-6">
        <!-- <app-number-input
    [label]="'Frequency'"
    [placeholder]="'Ex: 7 days'"
    [name]="'frequency'"
    [showButtons]="true"
    [control]="formGroup?.controls?.controlFrequency"
    [type]="'number'"
  ></app-number-input> -->
        <app-suggestion-input
          [label]="'Frequency'"
          [placeholder]="'Ex: 7 days'"
          [control]="formGroup?.controls?.controlFrequency"
          [viewMode]="fieldViewMode"
          [items]="[
            { label: 'Yearly', value: 365 },
            { label: 'Quarterly', value: 90 },
            { label: 'Monthly', value: 30 },
            { label: 'Weekly', value: 7 },
            { label: 'Daily', value: 1 },
          ]"
          [type]="'number'"
          [min]="0"
          [maxFractionDigits]="0"
        >
        </app-suggestion-input>
        <!-- <app-drop-down-input
  [label]="'Frequency'"
  [placeholder]="'Select Item'"
  [name]="'type'"
  [optionLabel]="'label'"
  [items]="listOfControlFrequencyTypes"
  [optionValue]="'value'"
  [control]="formGroup?.controls?.controlFrequency"
  [viewMode]="fieldViewMode"
  [badgeView]="true"
></app-drop-down-input> -->
      </div>
      <div class="col-12 md:col-6">
        <app-basic-input
          label="Source Of Control"
          [placeholder]="'Enter Source Of Control'"
          [control]="formGroup?.controls?.sourceOfControl"
          [viewMode]="fieldViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-basic-input
          label="Source Reference"
          [placeholder]="'Enter Source Reference'"
          [control]="formGroup?.controls?.sourceReference"
          [viewMode]="fieldViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-basic-input
          label="Unique Identifier"
          [placeholder]="'Enter Unique Identifier'"
          [control]="formGroup?.controls?.uniqueIdentifier"
          [viewMode]="fieldViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12">
        <app-switch-input
          [label]="moduleFieldString + '.allowExceptions.label' | translate: 'Allow Exceptions'"
          [placeholder]="moduleFieldString + '.allowExceptions.placeholder' | translate: 'Enter Allow Exceptions'"
          [name]="'allowExceptions'"
          [control]="formGroup?.controls?.allowExceptions"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>

      @if (formGroup?.controls?.allowExceptions?.value) {
        <div class="col-12 md:col-6">
          <app-date-input
            [label]="moduleFieldString + '.exceptionDuration.label' | translate: 'Exception Duration'"
            [placeholder]="'2022-11-22'"
            [name]="'exceptionDuration'"
            [showIcon]="true"
            [control]="formGroup?.controls?.exceptionDuration"
            [viewMode]="fieldViewMode"
            [minDate]="minDate"
          ></app-date-input>
        </div>
      }
      <div class="col-12 md:col-12">
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'textarea'"
          label="Description"
          [placeholder]="'Enter Text...'"
          [control]="formGroup?.controls?.description"
          [viewMode]="canEditField"
        ></app-text-editor>
      </div>
      <p-divider class="w-full" align="left">
        <b>Control Scope</b>
      </p-divider>
      <div class="col-12 md:col-6">
        <!-- <app-entity-category-tree-selector
  [label]="'Entities'"
  [categoryList]="categoryList"
  [placeholder]="'Search Entities'"
  [control]="formGroup?.controls?.controlEntities"
  [multi]="true"
  [viewMode]="fieldViewMode"
  [selectedEntities]="data?.controlEntities"
  [dropDown]="true"
  >
</app-entity-category-tree-selector> -->
        <app-target-code-selector
          [label]="'Entities'"
          [placeholder]="'Search Entities'"
          [control]="formGroup?.controls?.controlEntities"
          [viewMode]="fieldViewMode"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="'Applicable To'"
          [placeholder]="'Search Entity Types'"
          [control]="formGroup?.controls?.applicableTo"
          [viewMode]="fieldViewMode == 'create' ? fieldViewMode : 'view'"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['ENTITY_TYPE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-number-input
          [label]="moduleFieldString + '.controlsNumber.label' | translate: 'Controls Number'"
          [placeholder]="moduleFieldString + '.controlsNumber.label' | translate: 'Controls Number'"
          [name]="'controlsNumber'"
          [showButtons]="true"
          [viewMode]="'view'"
          [control]="formGroup?.controls?.controlsNumber"
          [type]="'number'"
        ></app-number-input>
      </div>
      <p-divider class="w-full" align="left">
        <b>Governance</b>
      </p-divider>
      <!-- <div class="col-12 md:col-6"
            *ngxPermissionsOnly="mapperService?.mappedFields['standard']?.permissions">
            <app-target-code-selector [label]="'Standard'"
                [placeholder]="'Search Standard'"
                [control]="formGroup?.controls?.standard"
                [viewMode]="canEditField"
                [multi]="false"
                [optionLabel]="'name'"
                [optionValue]="undefined"
                [dataKey]="'code'"
                [customProjectionFields]="['code','recordStatus','label','name','color','icon','options']"
                [targetTypes]="['STANDARD']"
                [extraFilters]="[{'property': 'status', 'operation': 'EQUAL', 'value': 'APPROVED'}]"> -->
      <!-- <app-target-code-selector [label]="'Policy'"
    [placeholder]="'Search Policies'"
    [control]="formGroup?.controls?.policy"
    [viewMode]="canEditField"
    [multi]="false"
    [optionLabel]="'name'"
    [optionValue]="undefined"
    [dataKey]="'code'"
    [customProjectionFields]="['code','recordStatus','label','name','color','icon','options','policyBody']"
    [targetTypes]="['POLICY']"
    [extraFilters]="[{'property': 'policyStatus', 'operation': 'EQUAL', 'value': 'APPROVED'}]"> -->
      <!-- </app-target-code-selector>
        </div> -->
      <div class="col-12 md:col-6" *ngxPermissionsOnly="mapperService?.mappedFields['controlObjectives']?.permissions">
        <app-target-code-selector
          [label]="'Control Objectives'"
          [placeholder]="'Search Objectives'"
          [control]="formGroup?.controls?.controlObjectives"
          [viewMode]="fieldViewMode"
          [multi]="true"
          [targetTypes]="['CONTROL_OBJECTIVE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Classification'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="listOfTypes"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.controlClassification"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
      <p-divider class="w-full" align="left">
        <b>Control Support</b>
      </p-divider>
      <div class="col-12 md:col-6" *ngxPermissionsOnly="mapperService?.mappedFields['standards']?.permissions">
        <app-target-code-selector
          [label]="'Standards'"
          [placeholder]="'Search Standards'"
          [helpText]="fieldViewMode == 'create' ? 'Cannot be changed after creation' : ''"
          [control]="formGroup?.controls?.standards"
          [viewMode]="canEditField"
          [extraFilters]="extraStandardFilters"
          [multi]="true"
          [targetTypes]="['STANDARD']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6" *ngxPermissionsOnly="mapperService?.mappedFields['guidelines']?.permissions">
        <app-target-code-selector
          [label]="'Guidelines'"
          [placeholder]="'Search Guidelines'"
          [helpText]="fieldViewMode == 'create' ? 'Cannot be changed after creation' : ''"
          [control]="formGroup?.controls?.guidelines"
          [viewMode]="canEditField"
          [extraFilters]="extraGuidelinesFilters"
          [multi]="true"
          [targetTypes]="['GUIDELINE']"
        >
        </app-target-code-selector>
      </div>
      <!-- <div class="col-12 "
            *ngxPermissionsOnly="mapperService?.mappedFields['controlProcedure']?.permissions">
            <app-target-code-selector [label]="'Control Procedure'"
                [placeholder]="'Search Control Procedures'"
                [helpText]="fieldViewMode=='create'?'Cannot be changed after creation':''"
                [control]="formGroup?.controls?.controlProcedure"
                [viewMode]="canEditField"
                [multi]="false"
                [targetTypes]="['CONTROL_PROCEDURE']">
            </app-target-code-selector>
        </div> -->
      @if (fieldViewMode == 'create') {
        <!-- <div class="col-12 align-items-center badge badge-outline-red flex flex-column gap-2 justify-content-center md:flex-row select-none"
            style="max-width: 100%; min-height: 1rem; min-width: 4rem; text-align: center;">
            <p class="text-red-400">Some Fields cannot be changed after creation</p>
        </div> -->
      }
      <p-divider class="w-full" align="left">
        <b>Attestation</b>
      </p-divider>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="'Questionnaire'"
          [placeholder]="'Search Questionnaires'"
          [control]="qsrControl"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [singleTableMode]="true"
          [optionLabel]="'name'"
          [optionValue]="undefined"
          [dataKey]="'code'"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'scoringType']"
          [targetTypes]="['QUESTIONNAIRE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="moduleFieldString + '.answerMode.label' | translate: 'Answer Mode'"
          [placeholder]="moduleFieldString + '.answerMode.placeholder' | translate: 'Select Answer Mode'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="answerModesList"
          [optionValue]="'value'"
          [optionDisabled]="'disabled'"
          [control]="formGroup?.controls?.answerMode"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
    </div>
  }
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
