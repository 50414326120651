import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  Control,
  ControlGroup,
  ControlGroupDto,
  EntityTypeFieldDto,
  FilterItem,
  GuidelineDto,
  ModuleKeywords,
  StandardDto,
  getEnumOptions,
} from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { ControlGroupMappingService } from '@shared/services/mappings/framework/control-group-mapping.service';

@Component({
  selector: 'app-control-group-item-form',
  templateUrl: './control-group-item-form.component.html',
  styleUrl: './control-group-item-form.component.scss',
})
export class ControlGroupItemFormComponent extends BaseForm<ControlGroupDto> implements OnInit {

  minDate = new Date();
  qsrControl = new FormControl(null);
  respList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  answerModesList: any = getEnumOptions(ControlGroup.AnswerModeEnum);
  categoryList = [
    EntityTypeFieldDto.CategoryEnum.Responsibility,
    EntityTypeFieldDto.CategoryEnum.Asset,
    EntityTypeFieldDto.CategoryEnum.Event,
    EntityTypeFieldDto.CategoryEnum.Objective,
    EntityTypeFieldDto.CategoryEnum.Process,
    EntityTypeFieldDto.CategoryEnum.Geography,
  ];

  extraStandardFilters: FilterItem[] = [
    { property: 'status', operation: 'EQUAL', value: StandardDto.StatusEnum.Published },
  ];

  extraGuidelinesFilters: FilterItem[] = [
    { property: 'status', operation: 'EQUAL', value: GuidelineDto.StatusEnum.Published },
  ];

  listOfTypes = getEnumOptions(Control.ControlClassificationEnum);
  @Input() showViewForm: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() canEditOwner: boolean = false;
  @Input() canEditEntities: boolean = false;
  // policyBodySectionFilter: FilterItem = { 'property': 'type', 'operation': 'EQUAL', 'value': PolicyBodySection.TypeEnum.ComplianceRequirements };

  constructor(
    public viewModeService: ViewModeService,
    public mapperService: ControlGroupMappingService
  ) {
    super(viewModeService, ModuleKeywords.ControlGroup);
    this.answerModesList.forEach((x, index) => {
      if (x.value == ControlGroup.AnswerModeEnum.Auto || x.value == ControlGroup.AnswerModeEnum.PreventCompliant) {
        this.answerModesList[index] = { label: x.label, value: x.value, disabled: true };
      }
    });
  }

  ngOnInit(): void {
    this.qsrControl.valueChanges.subscribe(element => {
      this.formGroup.controls.controlAttestationTemplate.patchValue(element?.code);
      this.answerModesList.forEach((x, index) => {
        if (x.value == ControlGroup.AnswerModeEnum.Auto || x.value == ControlGroup.AnswerModeEnum.PreventCompliant) {
          if (!element?.scoringType || element?.scoringType == 'NOT_SCORED') {
            this.answerModesList[index] = { label: x.label, value: x.value, disabled: true };
          } else {
            this.answerModesList[index] = { label: x.label, value: x.value, disabled: false };

          }
        }
      });
    })
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.getChangedFormValues(this.formatGetData()).updateItems;
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    // ret.policy = ret.policy.code;
    return ret;
  }
  setData(data: any) {
    let patchVal = { ...data };
    if (patchVal?.policy) patchVal.policy = { code: data?.policy };
    this.qsrControl.patchValue(data?.controlAttestationTemplate ? { code: data?.controlAttestationTemplate } : null);
    this.formGroup.patchValue({ ...patchVal });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      description: new FormControl(null),
      // standard: new FormControl(null, Validators.required),
      controlClassification: new FormControl(null, Validators.required),
      applicableTo: new FormControl(null),
      controlFrequency: new FormControl(null, Validators.required),
      attestationRespondent: new FormControl(null, Validators.required),
      controlAttestationTemplate: new FormControl(null),
      controlEntities: new FormControl(null),
      controlObjectives: new FormControl(null),
      guidelines: new FormControl(null),
      standards: new FormControl(null),
      sourceOfControl: new FormControl(null),
      sourceReference: new FormControl(null),
      uniqueIdentifier: new FormControl(null),
      controlsNumber: new FormControl(null),
      answerMode: new FormControl(ControlGroupDto.AnswerModeEnum.Manual, Validators.required),
      questionnaireScoredType: new FormControl(null),
      exceptionDuration: new FormControl(null),
      allowExceptions: new FormControl(null),
      // controlProcedure: new FormControl(null, Validators.required),
    });


  }
  get canEditField() {
    return this.fieldViewMode == 'create' ? this.fieldViewMode : this.canEdit ? this.fieldViewMode : 'view';
  }
}
